<template>
  <div>
    <b-row class="content-header">
      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Site Ops Urn Internment Reports
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <validation-observer
      ref="deceasedCategorizedReportForm"
      #default="{invalid}"
    >
      <b-form @submit.prevent="generateReport">
        <b-card
          header-tag="header"
        >
          <b-row>
            <b-col cols="md-12">
              <b-form-group
                label="By"
                label-for="h-by"
                label-cols-md="1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Report Type"
                  vid="reportType"
                  rules="required"
                >
                  <b-form-radio-group
                    id="h-reportType"
                    v-model="reportType"
                    button-variant="outline-primary"
                    buttons
                    class="spaceBetweenRadio"
                  >
                    <b-form-radio
                      v-for="(option, index) in reportOptions"
                      :key="index"
                      :value="option.value"
                    >
                      <span class="align-middle">{{ option.text }}</span>
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="reportTypeValidation"
                    class="text-danger"
                  >
                    {{ reportTypeError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="reportType != 'date'"
              cols="md-12"
            >
              <validation-provider
                #default="{ errors }"
                name="Year"
                vid="year"
                rules="required"
              >
                <b-form-group
                  label="Year"
                  label-for="h-user-year"
                  label-cols-md="1"
                  :state="(errors.length > 0 || yearValidation) ? false : null"
                >
                  <b-form-tags
                    v-model="year"
                    size="lg"
                    add-on-change
                    no-outer-focus
                  >
                    <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                      <ul
                        v-if="tags.length > 0"
                        class="list-inline d-inline-block mb-1 user__lists"
                      >
                        <li
                          v-for="tag in tags"
                          :key="tag"
                          class="list-inline-item"
                        >
                          <b-form-tag
                            :title="tag"
                            :disabled="disabled"
                            variant="danger"
                            @remove="removeTag(tag)"
                          >
                            {{ tag }}
                          </b-form-tag>
                        </li>
                      </ul>
                      <b-form-select
                        v-bind="inputAttrs"
                        v-on="inputHandlers"
                      >
                        <template v-slot:first>
                          <!-- This is required to prevent bugs with Safari -->
                          <option
                            disabled
                            value=""
                          >
                            Choose year(s)...
                          </option>
                        </template>
                        <option
                          v-for="(opt, ind) in yearOptions"
                          :key="ind"
                          :value="opt.name"
                        >
                          {{ opt.name }}
                        </option>
                      </b-form-select>
                    </template>
                  </b-form-tags>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="yearValidation"
                    class="text-danger"
                  >
                    {{ yearError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="reportType == 'date'"
              cols="md-12"
            >
              <validation-provider
                #default="{ errors }"
                name="Date"
                vid="date"
                rules="required"
                class="date__picker_block white-bg-dp mr-2"
              >
                <b-form-group
                  label="Date Range"
                  label-cols-md="1"
                  :state="(errors.length > 0 || dateValidation) ? false : null"
                >
                  <b-input-group>
                    <flat-pickr
                      id="main-date"
                      v-model="dateRange"
                      class="form-control"
                      placeholder="Select a date range"
                      :config="flatPickrConfig"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="CalendarIcon"
                        class="cursor-pointer"
                        data-toggle
                        size="18"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="dateValidation"
                    class="text-danger"
                  >
                    {{ dateError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-6">
              <b-form-checkbox
                v-if="reportType == 'year'"
                v-model="trendLine"
                value="accepted"
                unchecked-value=""
                class="custom-control-primary"
              >
                Add trendline to charts
              </b-form-checkbox>
            </b-col>
            <b-col
              cols="6"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="float-right"
                :disabled="invalid"
                @click="generateReport"
              >
                <span class="align-middle">Generate Report</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
    <b-card
      v-if="reportType == 'year' && showYearReportCard"
      header-tag="header"
      class="chart-card report__card"
    >
      <template #header>
        <h3 class="align-middle mr-auto">
          Site Ops Urn Internment (Year to Year)
        </h3>
        <b-button
          type="button"
          variant="flat-primary"
          class="float-right"
          @click="downloadCSV('yearTable', 'Site-Ops-Urn-Internment-Year-Reports')"
        >
          <feather-icon
            icon="DownloadIcon"
            class="mr-50"
            size="20"
          />
          <span class="">Download Excel</span>
        </b-button>
      </template>
      <b-row>
        <b-col
          cols="12"
          class="item__description_table"
        >
          <b-table
            id="yearTable"
            class="position-relative has_padding event_list"
            :items="yearTableData"
            responsive
            :fields="yearTableColumns"
            :sort-by.sync="yearTableSortBy"
            show-empty
            no-sort-reset
            empty-text="No matching records found"
            :sort-desc.sync="yearTableIsSortDirDesc"
            :sort-compare="sortYearTable"
          >
            <template #cell(year)="data">
              <div class="text-nowrap">
                <span class="text-bold-black">{{ resolveYearName(data.item.year) }}</span>
              </div>
            </template>
            <template
              v-for="category in deceasedCategories"
              v-slot:[`cell(${category.text})`]="data"
            >
              <!-- eslint-disable -->
              <div class="text-nowrap">
                <p
                  class="mb-0"
                  v-html="data.item[category.text]"
                />
              </div>
              <!-- eslint-enable -->
            </template>
            <template #cell(total)="data">
              <div class="text-nowrap">
                <!-- eslint-disable -->
                <p
                  class="mb-0"
                  v-html="data.item.total"
                />
                <!-- eslint-enable -->
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <div class="chats__block">
        <b-row class="match-height">
          <b-col
            cols="12"
          >
            <chartjs-bar-chart
              :title="'Site Ops Urn Internment (Year to Year)'"
              :year-bar-chart-data="yearBarChartData"
            />
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col
            v-for="(item, index) in yearPolarChartData"
            :key="index"
            cols="4"
          >
            <chartjs-polar-area-chart
              :title="item.name"
              :year-polar-chart-data="item.data"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>

    <div v-if="reportType == 'month' && showMonthReportCard">
      <b-card
        v-for="(yearData, key) in monthReportData"
        :key="key"
        header-tag="header"
        class="chart-card report__card"
      >
        <template #header>
          <h3 class="align-middle mr-auto">
            {{ yearData._id }} Site Ops Urn Internment
          </h3>
          <b-button
            type="button"
            variant="flat-primary"
            class="float-right"
            @click="downloadCSV(`monthTable${key}`, `${yearData._id}-Site-Ops-Urn-Internment-Month-Reports`)"
          >
            <feather-icon
              icon="DownloadIcon"
              class="mr-50"
              size="20"
            />
            <span class="">Download Excel</span>
          </b-button>
        </template>
        <b-row>
          <b-col
            cols="12"
            class="item__description_table"
          >
            <b-table
              :id="'monthTable'+key"
              class="position-relative has_padding event_list"
              :items="monthTableData[key]"
              responsive
              :fields="monthTableColumns"
              :sort-by.sync="monthTableSortBy"
              show-empty
              no-sort-reset
              empty-text="No matching records found"
              :sort-desc.sync="monthTableIsSortDirDesc"
              :sort-compare="sortYearTable"
              :tbody-tr-class="rowClass"
            >
              <template #cell(type)="data">
                <div class="text-nowrap">
                  <span class="text-bold-black">{{ data.item.type }}</span>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        header-tag="header"
        class="chart-card report__card"
      >
        <div class="chats__block">
          <b-row class="match-height">
            <b-col
              cols="12"
            >
              <chartjs-line-chart
                :title="''"
                :month-line-chart-data="monthLineChartData"
              />
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>

    <div v-if="reportType == 'date' && showDateReportCard">
      <b-card
        header-tag="header"
        class="chart-card report__card"
      >
        <template #header>
          <h3 class="align-middle mr-auto">
            {{ dateRangeData }} - Site Ops Urn Internment
          </h3>
          <b-button
            type="button"
            variant="flat-primary"
            class="float-right"
            @click="downloadCSV('dateTable', 'Site-Ops-Urn-Internment-Date-To-Date-Reports')"
          >
            <feather-icon
              icon="DownloadIcon"
              class="mr-50"
              size="20"
            />
            <span class="">Download Excel</span>
          </b-button>
        </template>
        <b-row>
          <b-col
            cols="12"
            class="item__description_table"
          >
            <b-table
              id="dateTable"
              class="position-relative has_padding event_list"
              :items="dateReportData"
              responsive
              :fields="fileTableHeadingItems"
              :sort-by.sync="dateTableSortBy"
              show-empty
              no-sort-reset
              empty-text="No matching records found"
              :sort-desc.sync="dateTableIsSortDirDesc"
            >
              <template #cell(stringID)="data">
                <div class="text-nowrap">
                  <b-button
                    v-if="canViewThisAction('show', 'ServiceRequest')"
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    variant="link"
                    class="weight-600 align-middle detail-view-id"
                    :to="{ name: 'operation-service-requests-show', params: { id: data.item._id } }"
                  >
                    {{ data.item.stringID }}
                  </b-button>
                  <b-button
                    v-else
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    variant="none"
                    class="text-bold-black align-middle detail-view-id not-button"
                  >
                    {{ data.item.stringID }}
                  </b-button>
                </div>
              </template>
              <template #cell(fileNo)="data">
                <div class="text-nowrap">
                  <span class="text-bold-black">{{ data.item.fileNo || '-' }}</span>
                </div>
              </template>
              <template #cell(serviceForm)="data">
                <div class="text-nowrap">
                  <span>{{ data.item.serviceForm.deceasedAngling || '-' }}</span>
                </div>
              </template>
              <template #cell(date)="data">
                <div class="text-nowrap">
                  <span class="">{{ dateFormat(data.item.date) }}</span>
                </div>
              </template>
              <template #cell(assignees)="data">
                <div
                  v-if="data.item.assignees.length"
                  class="text-nowrap"
                >
                  <span
                    v-for="(user, key) in data.item.assignees"
                    :key="key"
                  >
                    {{ user.name }}<span v-if="key < (data.item.assignees.length - 1)">, </span>
                  </span>
                </div>
                <div v-else>
                  -
                </div>
              </template>
              <template #cell(lst)="data">
                <div class="text-nowrap">
                  <span>{{ data.item.urnEntitlementFor == 'LST' ? 1 : '-' }}</span>
                </div>
              </template>
              <template #cell(fsp)="data">
                <div class="text-nowrap">
                  <span>{{ data.item.urnEntitlementFor == 'FSP' ? 1 : '-' }}</span>
                </div>
              </template>
              <template #cell(site)="data">
                <div class="text-nowrap">
                  <span>{{ data.item.urnEntitlementFor == 'SITE' ? 1 : '-' }}</span>
                </div>
              </template>
              <template #cell(fsp-non-check-in)="data">
                <div class="text-nowrap">
                  <span>{{ data.item.urnEntitlementFor == 'FSP- Non Check In' ? 1 : '-' }}</span>
                </div>
              </template>
              <template
                slot="bottom-row"
              >
                <td>
                  <span class="text-bold-black">Total 共计: {{ dateReportDataCount }} Service Requests</span>
                </td>
                <td />
                <td />
                <td />
                <td />
                <td>
                  <span class="text-bold-black">{{ dateReportLSTCount }}</span>
                </td>
                <td>
                  <span class="text-bold-black">{{ dateReportFSPCount }}</span>
                </td>
                <td>
                  <span class="text-bold-black">{{ dateReportSITECount }}</span>
                </td>
                <td>
                  <span class="text-bold-black">{{ dateReportFSPNCICount }}</span>
                </td>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BForm, BButton, BFormTags, BFormTag, BFormSelect, BFormGroup, BFormRadio, BFormRadioGroup, BInputGroup,
  BInputGroupAppend, BTable, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ChartjsBarChart from './chartjs/ChartjsBarChart.vue'
import ChartjsLineChart from './chartjs/ChartjsLineChart.vue'
import ChartjsPolarAreaChart from './chartjs/ChartjsPolarAreaChart.vue'

const timezone = process.env.VUE_APP_TIME_ZONE
const { canViewThisAction } = useAclUtils()

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormTags,
    BFormTag,
    BFormSelect,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    BInputGroup,
    BFormCheckbox,
    BInputGroupAppend,
    BTable,

    flatPickr,
    ChartjsBarChart,
    ChartjsLineChart,
    ChartjsPolarAreaChart,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      canViewThisAction,
      trendLine: 'accepted',
      reportType: 'year',
      showYearReportCard: false,
      showMonthReportCard: false,
      showDateReportCard: false,
      reportOptions: [
        { text: 'By years (With Comparison)', value: 'year' },
        { text: 'By months (With Comparison)', value: 'month' },
        { text: 'By File No.', value: 'date' },
      ],
      deceasedCategoryOption: [],
      deceasedCategoryOptions: [
        { text: 'Pre-Need 预先需要' },
        { text: 'As-Need 根据需要' },
        { text: 'Relocation 搬迁' },
      ],
      deceasedCategoryYearOption: [
        { text: 'Pre-Need 预先需要' },
        { text: 'As-Need 根据需要' },
        { text: 'Relocation 搬迁' },
        { text: 'Total 共计' },
      ],
      deceasedCategories: [
        { text: 'Pre-Need' },
        { text: 'As-Need' },
        { text: 'Relocation' },
      ],
      yearReportTableData: [],
      yearReportTableDataValues: [
        { preNeed: 220, asNeed: 100, relocation: 50 },
        { preNeed: 120, asNeed: 60, relocation: 30 },
      ],
      yearOptions: [],
      flatPickrConfig: {
        wrap: true, mode: 'range', dateFormat: 'd/m/Y', enableTime: false, disableMobile: true,
      },
      monthOptionsOnly: [
        { key: '1', title: 'Jan' },
        { key: '2', title: 'Feb' },
        { key: '3', title: 'Mar' },
        { key: '4', title: 'Apr' },
        { key: '5', title: 'May' },
        { key: '6', title: 'Jun' },
        { key: '7', title: 'Jul' },
        { key: '8', title: 'Aug' },
        { key: '9', title: 'Sep' },
        { key: '10', title: 'Oct' },
        { key: '11', title: 'Nov' },
        { key: '12', title: 'Dec' },
      ],
      fileTableHeadingItems: [
        { key: 'stringID', label: 'ID', sortable: true },
        { key: 'fileNo', label: 'File', sortable: true },
        { key: 'serviceForm', label: 'Lot No.', sortable: false },
        { key: 'date', label: 'Service Request Date', sortable: false },
        { key: 'assignees', label: 'Service Staff', sortable: false },
        { key: 'lst', label: 'LST', sortable: false },
        { key: 'fsp', label: 'FSP', sortable: false },
        { key: 'site', label: 'SITE', sortable: false },
        { key: 'fsp-non-check-in', label: 'FSP- Non Check In', sortable: false },
      ],
      reportTypeValidation: false,
      yearError: 'Valid year is required',
      yearValidation: false,
      year: [],
      month: [],
      dateError: 'Valid date is required',
      dateRangeData: '',
      dateRange: '',
      dateValidation: false,
      colorOptions: [
        '#104D9D', '#179CA6', '#F2B231', '#F36554', '#383D3B', '#6F5F75', '#9883AB', '#EB212E', '#F7A1A3', '#FF4D80',
        '#85B79D', '#21BB8B', '#9999A1', '#808772', '#51513D', '#A57548', '#CBA5A2', '#8C4843', '#633042', '#57276E',
        '#ACC18A', '#9DC7C8', '#98D2EB', '#119285', '#95D082', '#DFDF2F', '#AB966D', '#D2AD63', '#FF9F43', '#FF5C00',
        '#146ADC', '#150578', '#7BDFF2', '#8FD225', '#BBADA0', '#C099E3', '#FF595E', '#D0520B', '#F27A6B', '#C33C54',
      ],
      yearBarChartData: {},
      yearPolarChartData: [],
      monthReportData: [],
      monthReportCountData: [],
      monthLineChartData: {},
      dateReportData: [],
      dateReportDataCount: 0,
      dateReportFSPCount: 0,
      dateReportLSTCount: 0,
      dateReportSITECount: 0,
      dateReportFSPNCICount: 0,
      yearTableColumns: [],
      yearTableData: [],
      yearTableSortBy: 'year',
      yearTableIsSortDirDesc: true,
      monthTableColumns: [],
      monthTableData: [],
      monthTableSortBy: 'type',
      monthTableIsSortDirDesc: false,
      dateTableSortBy: 'stringID',
      dateTableIsSortDirDesc: true,

      // validation rules
      required,
    }
  },
  beforeMount() {
    // this.$http.get('operation/reports/ceremonial-service/extra-options')
    //   .then(() => {
    //     // this.yearOptions = response.data.yearOptions ?? []
    //     const currentYear = new Date().getFullYear()
    //     // eslint-disable-next-line no-plusplus
    //     for (let year = process.env.VUE_APP_REPORT_YEAR; year <= currentYear; year++) {
    //       const yearObj = {
    //         _id: year,
    //         name: `${year}`,
    //       }
    //       this.yearOptions.push(yearObj)
    //     }
    //     this.year = this.yearOptions.slice(-3).map(a => a.name)
    //   })
    //   .catch(error => {
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //   })
    const currentYear = new Date().getFullYear()
    // eslint-disable-next-line no-plusplus
    for (let year = process.env.VUE_APP_REPORT_YEAR; year <= currentYear; year++) {
      const yearObj = {
        _id: year,
        name: `${year}`,
      }
      this.yearOptions.push(yearObj)
    }
    this.year = this.yearOptions.slice(-3).map(a => a.name)
  },
  methods: {
    rowClass(item, type) {
      if (item && type === 'row') {
        if (item.bold === true) {
          return 'text-bold-black'
        }
      }
      return null
    },
    sortYearTable(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
      const canSortA = aRow.sortable
      const canSortB = bRow.sortable
      // if (canSortA && !canSortB) {
      //   console.log('ok')
      //   return -1
      // }
      if ((!canSortA || !canSortB) && sortDesc) {
        return -1
      }
      if ((!canSortA || !canSortB) && !sortDesc) {
        return 1
      }
      const a = aRow[key]
      const b = bRow[key]
      // if (typeof Number(b) === 'number' && typeof a === 'string' && sortDesc) {
      //   return -1
      // }
      if (
        (typeof a === 'number' && typeof b === 'number')
        || (a instanceof Date && b instanceof Date)
      ) {
        // eslint-disable-next-line no-nested-ternary
        return a < b ? -1 : (a > b ? 1 : 0)
      }
      return this.toString(a).localeCompare(this.toString(b), compareLocale, compareOptions)
    },
    toString(value) {
      if (value === null || typeof value === 'undefined') {
        return ''
      }
      if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map(key => this.toString(value[key]))
          .join(' ')
      }
      return String(value)
    },
    downloadCSV(reference, fileName, separator = ',') {
      // let csvData = []
      // const rows = document.getElementById(reference).getElementsByTagName('tr')
      // // eslint-disable-next-line no-plusplus
      // for (let i = 0; i < rows.length; i++) {
      //   const cols = rows[i].querySelectorAll('td, th')
      //   const csvRow = []
      //   // eslint-disable-next-line no-plusplus
      //   for (let j = 0; j < cols.length; j++) {
      //     csvRow.push(cols[j].textContent)
      //   }

      //   csvData.push(csvRow.join(','))
      // }
      // csvData = csvData.join('\n')

      // const CSVFile = new Blob([csvData], { type: 'text/csv' })

      // const tempLink = document.createElement('a')

      // tempLink.download = `${fileName}.csv`
      // const url = window.URL.createObjectURL(CSVFile)
      // tempLink.href = url

      // tempLink.style.display = 'none'
      // document.body.appendChild(tempLink)
      // tempLink.click()
      // document.body.removeChild(tempLink)
      const rows = document.getElementById(reference).getElementsByTagName('tr')
      const csv = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < rows.length; i++) {
        const row = []; const
          cols = rows[i].querySelectorAll('td, th')
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < cols.length; j++) {
          const clone = cols[j].cloneNode(true)
          const spans = clone.querySelector('.sr-only')
          if (spans) {
            clone.removeChild(spans)
          }

          const result = clone.textContent || clone.innerText
          let data = result.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
          data = data.replace(/"/g, '""')
          row.push(`"${data}"`)
        }
        csv.push(row.join(separator))
      }
      const csvString = csv.join('\n')
      // Download it
      const filename = `${fileName}.csv`
      const link = document.createElement('a')
      link.style.display = 'none'
      link.setAttribute('target', '_blank')
      link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(csvString)}`)
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    generateReport() {
      this.$refs.deceasedCategorizedReportForm.validate().then(success => {
        if (success) {
          if (this.reportType === 'year') {
            this.generateYearReport()
          } else if (this.reportType === 'month') {
            this.generateMonthReport()
          } else if (this.reportType === 'date') {
            this.generateDateReport()
          }
        }
      })
    },
    resolveYearName(id) {
      // eslint-disable-next-line
        if (id == moment().utcOffset(timezone).format('YYYY')) {
        return `YTD (as at ${moment().utcOffset(timezone).format('DD/MM/YYYY')})`
      }
      // eslint-disable-next-line
        if (id < moment().utcOffset(timezone).format('YYYY')) {
        return `Prior Year (${id})`
      }
      return id
    },
    resolveDeceasedCategoryDataInYear(deceasedCategory, year) {
      const yearData = this.yearReportTableData.find(o => o._id === year)
      if (yearData) {
        const flowData = yearData.urnEntitlementCounts.find(o => o.urnEntitlementID === deceasedCategory)
        if (flowData) {
          return flowData.count
        }
      }
      return 0
    },
    resolveDeceasedCategoryDataInYearPercentage(deceasedCategory) {
      const flowDataY1 = this.yearReportTableData[0].urnEntitlementCounts.find(o => o.urnEntitlementID === deceasedCategory)
      let count1 = 0
      if (flowDataY1) {
        count1 = flowDataY1.count
      }

      const flowDataY2 = this.yearReportTableData[1].urnEntitlementCounts.find(o => o.urnEntitlementID === deceasedCategory)
      let count2 = 0
      if (flowDataY2) {
        count2 = flowDataY2.count
      }

      const profit = count1 - count2
      let percentage = 0
      if (count1 === 0 && count2 === 0) {
        percentage = (0).toFixed(2)
      } else if (count2 === 0) {
        percentage = (profit * 100).toFixed(2)
      } else if (count1 === 0) {
        percentage = (profit * 100).toFixed(2)
      } else {
        percentage = ((profit / count2) * 100).toFixed(2)
      }
      if (percentage >= 0) {
        return `<span class="text-bold-black" style="color: #104D9D;">${percentage}%</span>`
      }
      return `<span class="text-bold-black" style="color: #D91B35;">${percentage}%</span>`
    },
    resolveTotalDeceasedCategoryDataInYearPercentage(y1, y2) {
      const profit = y1 - y2
      let percentage = 0
      if (y1 === 0 && y2 === 0) {
        percentage = (0).toFixed(2)
      } else if (y2 === 0) {
        percentage = (profit * 100).toFixed(2)
      } else if (y1 === 0) {
        percentage = (profit * 100).toFixed(2)
      } else {
        percentage = ((profit / y2) * 100).toFixed(2)
      }
      if (percentage >= 0) {
        return `<span class="text-bold-black" style="color: #104D9D;">${percentage}%</span>`
      }
      return `<span class="text-bold-black" style="color: #D91B35;">${percentage}%</span>`
    },
    resolveDeceasedCategoryDataInMonth(deceasedCategory, data) {
      const flowData = data.find(o => o.urnEntitlementID === deceasedCategory)
      if (flowData) {
        return flowData.count
      }
      return 0
    },
    resolveDeceasedCategoryDataInYearForMonthReport(deceasedCategory, year) {
      const yearData = this.monthReportCountData.find(o => o._id === year)
      if (yearData) {
        const flowData = yearData.urnEntitlementCounts.find(o => o.urnEntitlementID === deceasedCategory)
        if (flowData) {
          return flowData.count
        }
      }
      return 0
    },
    generateYearReport() {
      const formData = new FormData()
      formData.append('year', JSON.stringify(this.year))
      this.$http.post('operation/reports/generate/deceased/year', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          if (response.data.sRequests) {
            this.yearPolarChartData = []
            this.yearReportTableData = response.data.sRequests
            const yearBarChartLabels = []
            const yearBarChartDataset = []
            const yearBarChartTotalDataset = []
            this.deceasedCategoryOption = this.deceasedCategoryOptions
            this.yearTableIsSortDirDesc = true
            this.yearTableColumns = []
            this.yearTableColumns.push({ key: 'year', label: 'Total (YTD)', sortable: true })
            this.deceasedCategoryYearOption.forEach(element => {
              yearBarChartLabels.push(element.text)
            })
            this.deceasedCategoryOption.forEach((element, key) => {
              this.yearTableColumns.push({ key: `${this.deceasedCategories[key].text}`, label: element.text, sortable: false })
            })
            this.yearTableColumns.push({ key: 'total', label: 'Total 共计', sortable: false })
            this.yearTableData = []
            this.yearReportTableData.forEach((element, colorKey) => {
              const yearData = []
              const yearPolarChartLabels = []
              const polarYearData = []
              const polarColors = []
              const dataObject = {}

              dataObject.year = this.resolveYearName(element._id)
              yearBarChartTotalDataset.push(element.totalThisYear)
              this.deceasedCategoryOption.forEach((category, key) => {
                const count = this.resolveDeceasedCategoryDataInYear(this.deceasedCategories[key].text, element._id)

                dataObject[this.deceasedCategories[key].text] = count
                yearData.push(count)
                polarYearData.push(count)
                polarColors.push(this.colorOptions[key])
                let percentage = 0
                if (element.totalThisYear === 0) {
                  percentage = (0).toFixed(2)
                } else {
                  percentage = ((count / element.totalThisYear) * 100).toFixed(2)
                }
                yearPolarChartLabels.push(`${category.text}, ${percentage}%`)
              })

              dataObject.total = element.totalThisYear
              dataObject.sortable = true

              yearData.push(element.totalThisYear)

              const polarChartObject = {

                name: `${element._id}, Total ${element.totalThisYear}`,
                data: {
                  labels: yearPolarChartLabels,
                  datasets: [
                    {
                      label: 'Population (millions)',
                      backgroundColor: polarColors,
                      data: polarYearData,
                      borderWidth: 0,
                    },
                  ],
                },
              }
              this.yearPolarChartData.push(polarChartObject)
              this.yearTableData.push(dataObject)

              const color = this.colorOptions[colorKey]
              const barObject = {
                type: 'bar',

                label: element._id,
                data: yearData,
                backgroundColor: color,
                borderColor: 'transparent',
                borderRadius: 0,
              }
              yearBarChartDataset.push(barObject)
              if (this.trendLine === 'accepted') {
                const lineObject = {
                  type: 'line',
                  label: '',
                  data: yearData,
                  borderColor: color,
                  lineTension: 0.5,
                  pointStyle: 'circle',
                  backgroundColor: color,
                  fill: false,
                  pointRadius: 1,
                  pointHoverRadius: 5,
                  pointHoverBorderWidth: 5,
                  pointBorderColor: 'transparent',
                  pointHoverBorderColor: '#fff',
                  pointHoverBackgroundColor: color,
                  pointShadowOffsetX: 1,
                  pointShadowOffsetY: 1,
                  pointShadowBlur: 5,
                  pointShadowColor: 'rgba(0, 0, 0, 0.25)',
                }
                yearBarChartDataset.push(lineObject)
              }
            })
            if (this.yearReportTableData.length > 1) {
              const extraRow = {}

              extraRow.year = `YTY (${this.yearReportTableData[0]._id} vs ${this.yearReportTableData[1]._id})`
              extraRow.sortable = false
              this.deceasedCategoryOptions.forEach((deceasedCategory, key) => {
                const count = this.resolveDeceasedCategoryDataInYearPercentage(this.deceasedCategories[key].text)

                extraRow[this.deceasedCategories[key].text] = count
              })
              extraRow.total = this.resolveTotalDeceasedCategoryDataInYearPercentage(this.yearReportTableData[0].totalThisYear, this.yearReportTableData[1].totalThisYear)
              this.yearTableData.push(extraRow)
            }
            this.$set(this.yearBarChartData, 'labels', yearBarChartLabels)
            this.$set(this.yearBarChartData, 'datasets', yearBarChartDataset)
            // eslint-disable-next-line prefer-object-spread
            this.showYearReportCard = true
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(error => {
          if (error.data.errors) {
            error.data.errors.forEach(validationError => {
              if (validationError.param === 'year') {
                this.yearError = validationError.msg
                this.yearValidation = true
              }
            })

            const items = document.getElementsByClassName('text-danger')
            window.scrollTo({
              top: items[0].offsetTop,
              behavior: 'smooth',
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    generateMonthReport() {
      const formData = new FormData()

      formData.append('year', JSON.stringify(this.year))
      formData.append('month', JSON.stringify(this.monthOptionsOnly.map(month => month.key)))
      this.$http.post('operation/reports/generate/deceased/month', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          if (response.data.sRequests) {
            this.deceasedCategoryOption = this.deceasedCategoryOptions
            this.monthReportData = response.data.sRequests
            this.monthReportCountData = response.data.sRequestsCount
            this.monthTableIsSortDirDesc = false
            this.monthTableData = []
            const monthLineChartLabels = []
            const monthLineChartDataset = []
            this.monthTableColumns = []
            this.monthTableColumns.push({ key: 'type', label: 'Types', sortable: true })
            this.monthOptionsOnly.forEach(element => {
              this.monthTableColumns.push({ key: element.key, label: element.title, sortable: false })
              monthLineChartLabels.push(element.title)
            })
            this.monthTableColumns.push({ key: 'total', label: 'Total', sortable: false })
            response.data.sRequests.forEach((element, colorKey) => {
              const yearData = []
              const dataArray = []
              this.deceasedCategoryOption.forEach((deceasedCategory, deceasedCategoryIndex) => {
                const dataObject = {}
                dataObject.type = deceasedCategory.text
                this.monthOptionsOnly.forEach((month, monthIndex) => {
                  dataObject[month.key] = this.resolveDeceasedCategoryDataInMonth(this.deceasedCategories[deceasedCategoryIndex].text, element.urnEntitlementCountsYear[monthIndex].data)
                })

                dataObject.total = this.resolveDeceasedCategoryDataInYearForMonthReport(this.deceasedCategories[deceasedCategoryIndex].text, element._id)
                dataObject.sortable = true
                dataArray.push(dataObject)
              })
              const grandTotalRow = {}
              grandTotalRow.type = 'Total 共计'
              grandTotalRow.sortable = false
              grandTotalRow.bold = true
              this.monthOptionsOnly.forEach((monthObj, monthIndex) => {
                grandTotalRow[monthObj.key] = element.urnEntitlementCountsYear[monthIndex].count
              })
              grandTotalRow.total = element.totalThisYear
              dataArray.push(grandTotalRow)
              this.monthTableData.push(dataArray)

              this.monthOptionsOnly.forEach((month, monthIndex) => {
                const { count } = element.urnEntitlementCountsYear[monthIndex]
                yearData.push(count)
              })

              const color = this.colorOptions[colorKey]
              const lineObject = {

                label: element._id,
                data: yearData,
                borderColor: color,
                lineTension: 0.1,
                pointStyle: 'circle',
                backgroundColor: '#000',
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
              }
              monthLineChartDataset.push(lineObject)
            })
            this.$set(this.monthLineChartData, 'labels', monthLineChartLabels)
            this.$set(this.monthLineChartData, 'datasets', monthLineChartDataset)
            this.showMonthReportCard = true
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(error => {
          if (error.data.errors) {
            error.data.errors.forEach(validationError => {
              if (validationError.param === 'year') {
                this.yearError = validationError.msg
                this.yearValidation = true
              }
            })

            const items = document.getElementsByClassName('text-danger')
            window.scrollTo({
              top: items[0].offsetTop,
              behavior: 'smooth',
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    generateDateReport() {
      const formData = new FormData()

      formData.append('date', JSON.stringify(this.dateRange))
      this.$http.post('operation/reports/generate/deceased/date', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          if (response.data.sRequests) {
            this.dateRangeData = this.dateRange
            this.dateReportData = response.data.sRequests
            this.dateReportDataCount = response.data.total
            this.dateReportFSPCount = response.data.fsp
            this.dateReportLSTCount = response.data.lst
            this.dateReportSITECount = response.data.site
            this.dateReportFSPNCICount = response.data.fspNCI
            this.showDateReportCard = true
            this.dateTableIsSortDirDesc = true
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(error => {
          if (error.data.errors) {
            error.data.errors.forEach(validationError => {
              if (validationError.param === 'date') {
                this.dateError = validationError.msg
                this.dateValidation = true
              }
            })

            const items = document.getElementsByClassName('text-danger')
            window.scrollTo({
              top: items[0].offsetTop,
              behavior: 'smooth',
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
  },
}
</script>
